<template>
    <div class="table">
      <div class="container">
        <div class="handle-box">
           <el-input
	          v-model="searchValus"
	          placeholder="关键字"
	          class="handle-input mr10"
	          @keyup.enter.native="search"
	        ></el-input>
            
              
        </div>
          <div class="handle-box flex-space-container">
          	<div>
                  <el-button
          type="primary"
          icon="search"
          @click="search"
        >查询</el-button>
       
       </div>
        
        </div>
        <el-table
          class="z_default_table"
          :empty-text="emptyText"
          :row-style="rowStyle"
          v-loading="tableLoading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          :data="tableData"
          border
          style="width: 100%"
          ref="multipleTable"
          use-virtual
          height="600"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="40"
          ></el-table-column>

<el-table-column
            align="center"
            prop="userName"
            label="用户名称"
         
          ></el-table-column>
            <el-table-column
            align="center"
            prop="wayBillNo"
            label="单号"
         
          ></el-table-column>
          <el-table-column
            align="center"
            prop="brandCode"
            label="品牌"
         
          ></el-table-column>
         
               <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
             :formatter="createTimeFormatter"
          ></el-table-column>
          

        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal"
          ></el-pagination>
        </div>
      </div>
 
 
    </div>
  </template>
 
  <script>
  import global from "@/common/global";
  import { decodeUserInfo,requestParams,currentDate } from "@/common/tools";
  export default {
     components: {
  },
    data() {
      return {
      	companyJobList: [],
      	perfectType: "",
        userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
        pageSize: global.pageSize,
        pageSizes: global.pageSizes,
        pageIndex: 1,
        beforeRow: null,
        tableData: [], //表格数据
        tableTotal: 0,
        searchValus: "",
        checkForm: {},
        multipleSelection: [], //选中的行
        checkVisible: false,
        labelPosition: "right",
        indexArr: [],
        tableLoading: false,
        deliverForm: {},
        deliverVisible: false,
        delVisible: false,
        addVisible: false,
        editVisible: false,
         form: {
        },
        expressUserId: "",
        drawerLog: false
    };
    },
    created() {
      this.getData();
    },
    updated() {
  
    },
    computed: {
    	
    },
    methods: {
      rowStyle({ row, rowIndex }) {
        for (var i in this.indexArr) {
          if (rowIndex == this.indexArr[i].index) {
            return "background:" + global.listColor + ";";
          }
        }
      },
        filterMethod(query, item) {
      // console.log(item.id, query)
      return (
        String(item.jobId).indexOf(query) > -1 ||
        String(item.jobCompanyName).indexOf(query) > -1
      );
    },
    expressShow(row) {
      console.log(row);
      this.expressUserId =row.userId;
      this.drawerLog = true;
    },
      handleSelectAll(selection) {
        this.$refs.multipleTable.clearSelection();
      },
      // 分页导航
      handleCurrentChange(val) {
        this.pageIndex = val;
        this.getData();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getData();
      },
      checkStatusTypeChange() {
      	  this.pageIndex = 1;
        this.getData();
      },handleAdd() {
        this.addVisible = true;
      },
      getData() {
        this.tableLoading = true;
        var data = {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          searchValus: this.searchValus

        };
        this.emptyText = " ";
        this.$axios
          .post("/oa/order/getOrderList", this.$qs.stringify(data))
          .then(res => {
              console.log(res);
            this.tableData = res.data.data.rows;
            this.tableLoading = false;
            this.tableTotal = Number(res.data.data.total);
            this.emptyText = "暂无数据";
          })
          .catch(error => {
            this.emptyText = "暂无数据";
            this.tableLoading = false;
          });
      },handleDeliver() {
      		var multipleSelection = this.multipleSelection;
      			if(multipleSelection.length >= 1) {
					 this.deliverVisible = true;
				} else {
					this.$message({
						showClose: true,
						message: "请选择需要操作的记录",
						type: "error"
					});
				}
       
      },handleDel() {
      var multipleSelection = this.multipleSelection;
      			if(multipleSelection.length >= 1) {
					  this.delVisible = true;
				} else {
					this.$message({
						showClose: true,
						message: "请选择需要操作的记录",
						type: "error"
					});
				}
     
    },saveDeliver(){
      	 var indexArr = this.indexArr;
	      var userIdStr = '';
	      var jobIdStr = '';
	   var data = {};
	   if (this.deliverForm.transferList){
	        var nArr = this.deliverForm.transferList;
	        for (var i in nArr) {
	          jobIdStr += nArr[i] + ',';
	        }
           jobIdStr = jobIdStr.substring(0, jobIdStr.length - 1);
	        data.jobIds = jobIdStr;
	     
	     if (this.indexArr.length >= 1) {
	        for (var i in indexArr) {
	          userIdStr += indexArr[i].id + ',';
	        }
	        userIdStr = userIdStr.substring(0, userIdStr.length - 1);
	        data.userIds = userIdStr;
	      }
	       data = requestParams(data);
	       this.$axios
        .post(
          "/api/companyjob/deliverCompanyJob",
          this.$qs.stringify(data)
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("投递成功");
            this.deliverVisible = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
	   }else{
	   	  this.$message.success("请选择需要投递的职位");
	   }
     
      },deleteRow(){
      	 var indexArr = this.indexArr;
	      var userIdStr = '';
	   var data = {};
	     if (this.indexArr.length >= 1) {
	        for (var i in indexArr) {
	          userIdStr += indexArr[i].id + ',';
	        }
	        userIdStr = userIdStr.substring(0, userIdStr.length - 1);
	        data.userIds = userIdStr;
	      }
	       data = requestParams(data);
	       this.$axios
        .post(
          "/oa/user/deleteUserData",
          this.$qs.stringify(data)
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("删除");
            this.delVisible = false;
             this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        });
     
      },saveAdd(){
        if (!this.addForm.userName || this.addForm.userName == "") {
             this.$message.error("姓名不能为空");
        }else if (!this.addForm.mobile || this.addForm.mobile == "") {
          this.$message.error("手机号码不能为空");
        }else if (!this.addForm.jobTitle || this.addForm.jobTitle == "") {
          this.$message.error("期望职位不能为空");
        }else {
          var data =this.addForm;
           data.educateList=JSON.stringify(data.educateList);
           data.workList=JSON.stringify(data.workList);
           data.projectList=JSON.stringify(data.projectList);
          this.$axios
            .post("/oa/user/saveUserData", this.$qs.stringify(data))
            .then(res => {
              if (res.data.code == 0) {
                this.addVisible = false;
                this.addForm={
                gender : 0,
	        	educateList: [{'schoolName':'','profession':'','education':'','schoolStartDate':'','schoolEndDate':''}],
	        	workList: [{'companyName':'','position':'','hireDate':'','termDate':''}],
	        	projectList: [{'projectName':'','roleName':'','startDate':'','endDate':''}] 
	        };
                //console.log(this.addForm);
                this.$message.success(`新增成功`);
                this.getData();
              } else {
                this.$message.error(res.data.message);
              }
            });
        }
      },saveEdit(){
        if (!this.form.userName || this.form.userName == "") {
             this.$message.error("姓名不能为空");
        }else if (!this.form.jobTitle || this.form.jobTitle == "") {
          this.$message.error("期望职位不能为空");
        }else {
              var data =this.form;
              data.educateList=JSON.stringify(data.educateList);
              data.workList=JSON.stringify(data.workList);
              data.projectList=JSON.stringify(data.projectList);
          this.$axios
            .post("/oa/user/updateUserData", this.$qs.stringify(data))
            .then(res => {
              if (res.data.code == 0) {
                this.editVisible = false;
                //console.log(this.addForm);
                this.$message.success(`新增成功`);
                this.getData();
              } else {
                this.$message.error(res.data.message);
              }
            });
        }
      }, handleEdit(row) {
      	var userId = row.userId;
      this.$axios
        .post(
          "/oa/user/getUserData",
          this.$qs.stringify({
            userId: userId
          })
        )
        .then(({ data }) => {
          console.log(data);
          if (data.code == 0) {
            this.form = data.data;
             this.editVisible = true;
          }
        });
   
    },addDataRow(type,num){
    	if("add"==type){
    		if (num =="1") {
    			this.addForm.educateList.push({'schoolName':'','profession':'','education':'','schoolStartDate':'','schoolEndDate':''});
    		}
    		if (num =="2") {
    			       this.addForm.workList.push({'companyName':'','position':'','hireDate':'','termDate':''});
    		}
    		if (num =="3") {
    			this.addForm.projectList.push({'projectName':'','roleName':'','startDate':'','endDate':''});
    		}
    	}else{
    		if (num =="1") {
    			this.form.educateList.push({'schoolName':'','profession':'','education':'','schoolStartDate':'','schoolEndDate':''});
    		}
    		if (num =="2") {
    			       this.form.workList.push({'companyName':'','position':'','hireDate':'','termDate':''});
    		}
    		if (num =="3") {
    			this.form.projectList.push({'projectName':'','roleName':'','startDate':'','endDate':''});
    		}
    	}
    	
    },
      search() {
      	this.pageIndex = 1;
        //   alert(this.select_cate);
        this.getData();
      },
       handleSelectionChange(val) {
        this.multipleSelection = val;
        this.indexArr = [];
        if (this.multipleSelection.length >= 1) {
          for (var i in this.tableData) {
            for (var j in this.multipleSelection) {
              if (this.tableData[i].userId == this.multipleSelection[j].userId) {
                this.indexArr[j] = {
                  index: i,
                  id: this.multipleSelection[j].userId
                };
              }
            }
          }
        }
      },
      handleCheckBox(selection, row) {
        // console.log(this.beforeRow, row, this.indexArr, this.multipleSelection)
        if (!this.beforeRow) {
          this.beforeRow = row;
        } else {
          if (this.beforeRow != row) {
            this.beforeRow = row;
            this.$refs.multipleTable.clearSelection();
            this.$refs.multipleTable.toggleRowSelection(row);
          } else {
            if (this.indexArr.length > 0) {
              this.$refs.multipleTable.clearSelection();
            } else {
              this.$refs.multipleTable.clearSelection();
              this.$refs.multipleTable.toggleRowSelection(row);
            }
          }
        }
      },
      handleRowClick(row, event, column) {
        if (!this.beforeRow) {
          this.beforeRow = row;
        } else {
          if (this.beforeRow != row) {
            this.beforeRow = row;
            this.$refs.multipleTable.clearSelection();
          }
        }
        this.$refs.multipleTable.toggleRowSelection(row);
      },    createTimeFormatter(row, column, cellValue, index) {
        return currentDate({
          timestamp: cellValue
        });
      },

    }
  };
  </script>
  
  <style scoped>
  .table {
    height: 100%;
  }
  
  .table,
  .container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }
  
  .table .el-table {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  }
  
  .handle-box {
    margin-bottom: 20px;
  }
  
  .mr10{
  	margin-right: 10px;
  }
  
  .handle-select {
    width: 120px;
  }
  
  .search_select {
    width: 160px !important;
  }
  
  .handle-input {
    width: 160px;
    display: inline-block;
  }
  
  .del-dialog-cnt {
    font-size: 16px;
    text-align: center;
  }
  
  .select_name {
    display: inline-block;
    font-size: 14px;
  }
  
  .select_container {
    display: inline-block;
  }
  
  .select_container .el-date-editor.el-input {
    width: 140px;
  }
  
  .brandMs p {
    text-align: left;
  }
   .edit_dev >>> .el-transfer-panel {
     width:350px;
   }
  </style>
  